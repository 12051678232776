import React, { useEffect, useRef } from 'react';
import { graphql } from 'gatsby';

import { useIntl, FormattedMessage } from 'gatsby-plugin-intl';
import { useMedia } from 'use-media';

import { gsap } from 'gsap';
import { Tween, SplitWords } from 'react-gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { MotionPathPlugin } from 'gsap/MotionPathPlugin';
import { ScrollToPlugin } from 'gsap/all';
import { AnimatePresence, motion } from 'framer-motion';
import { Helmet } from 'react-helmet';
// import { isMobileSafari } from 'react-device-detect';
// import scrollTo from 'gatsby-plugin-smoothscroll';
import Image from 'gatsby-image';

import Header from '../../components/header';
import Footer from '../../components/footer';
import SelectLang from '../../components/selectLang';

import ClientOnly from '../../components/clientOnly';
import Cursor from '../../components/cursor';

import { Section, Div, P, H1, A, H3, H4, NavLink, Span } from '../../components/styles';
import Layout from '../../components/layout';
import SEO from '../../components/seo';

gsap.registerPlugin(ScrollToPlugin, ScrollTrigger, MotionPathPlugin);

const AboutUs = ({ data }) => {
  const Intl = useIntl();
  const isTablet = useMedia({ minWidth: '768px' });
  const introHeadingRef = useRef();

  useEffect(() => {
    const { body } = document;
    const scrollY = body.style.top;
    body.style.position = '';
    body.style.top = '';
    window.scrollTo(0, parseInt(scrollY || '0') * -1);
    document.querySelector('body').classList.remove('prevent-scroll');
  }, []);

  return (
    <Layout>
      <SEO
        title={Intl.formatMessage({ id: 'title' })}
        desc={Intl.formatMessage({ id: 'description' })}
        keywords={Intl.formatMessage({ id: 'keywords' })}
      />

      <Helmet>
        <script>
          {`var _paq = window._paq = window._paq || [];

          _paq.push(['trackPageView']);
          _paq.push(['enableLinkTracking']);
          (function() {
            var u="//ec2-18-197-155-62.eu-central-1.compute.amazonaws.com/";
            _paq.push(['setTrackerUrl', u+'matomo.php']);
            _paq.push(['setSiteId', '2']);
            var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];
            g.async=true; g.src=u+'matomo.js'; s.parentNode.insertBefore(g,s);
          })();`}
        </script>
      </Helmet>
      <main>
        <ClientOnly>
          {isTablet && (
            <Cursor color="0, 255, 36" outerAlpha={1} innerSize={6} outerSize={16} outerScale={2} />
          )}
        </ClientOnly>
        <Header />
        <Section id="lang-nav" bg="var(--section-bg-color)">
          <Div className="container" display="grid" maxWidth="1440px" mx="auto">
            <Div
              display="grid"
              gridColumn="1 / 4"
              alignSelf="end"
              justifyContent={['center', 'center', 'end']}
            >
              <AnimatePresence>
                <motion.div initial={{ y: -100 }} animate={{ y: 0 }} exit={{ y: -100 }}>
                  <SelectLang />
                </motion.div>
              </AnimatePresence>
            </Div>
          </Div>
        </Section>
        <Section id="about-us" bg="var(--section-bg-color)" minHeight="100vh" data-section={1}>
          <Div
            className="trigger-about-text"
            display="flex"
            alignItems="center"
            maxWidth="1440px"
            minHeight={['100vh', '100vh', '100vh', '100vh', '680px']}
            mx="auto"
            px={['8.75%', '8.75%', '8.75%', '8rem']}
          >
            <Div maxWidth="954px">
              <Tween
                from={{
                  autoAlpha: 0,
                  y: 50,
                }}
                to={{
                  scrollTrigger: {
                    trigger: '.trigger-about-text',
                    start: 'top -50%',
                  },
                  autoAlpha: 1,
                  y: 0,
                }}
                duration={0.5}
                stagger={0.2}
              >
                <P
                  color="var(--section-text-color)"
                  fontSize={['1.85rem', '1.85rem', '2.8rem']}
                  lineHeight={['2rem', '2rem', '3.3rem']}
                  fontWeight={700}
                  m={0}
                >
                  {Intl.formatMessage({
                    id: 'aboutUs.section1.text1',
                  })}
                </P>
                <P
                  color="var(--section-text-color)"
                  fontSize={['1.85rem', '1.85rem', '2.8rem']}
                  lineHeight={['2rem', '2rem', '3.3rem']}
                  fontWeight={700}
                >
                  {Intl.formatMessage({
                    id: 'aboutUs.section1.text2',
                  })}
                </P>
                <P
                  color="var(--section-text-color)"
                  fontSize={['1.85rem', '1.85rem', '2.8rem']}
                  lineHeight={['2rem', '2rem', '3.3rem']}
                  fontWeight={700}
                  m={0}
                >
                  {Intl.formatMessage({
                    id: 'aboutUs.section1.text3',
                  })}
                </P>
                <P
                  color="var(--section-text-color)"
                  fontSize={['1.85rem', '1.85rem', '2.8rem']}
                  lineHeight={['2rem', '2rem', '3.3rem']}
                  fontWeight={700}
                >
                  {Intl.formatMessage({
                    id: 'aboutUs.section1.text4',
                  })}
                </P>
              </Tween>
            </Div>
          </Div>
        </Section>
        <Section id="team" minHeight="100vh" data-section={2}>
          <Div
            display="grid"
            className="trigger-founders"
            alignItems="center"
            maxWidth="1440px"
            minHeight={['100vh', '100vh', '100vh', '100vh', '680px']}
            mx="auto"
            px={['8.75%', '8.75%', '8.75%', '8rem']}
            py={['5%', '5%', '10%', '10%']}
          >
            <Div
              display="grid"
              gridGap="80px"
              gridTemplateColumns={['1fr', '1fr', '1fr', '1fr', '240px 1fr']}
            >
              <Tween
                from={{
                  autoAlpha: 0,
                  y: 50,
                }}
                to={{
                  scrollTrigger: {
                    trigger: '.trigger-founders',
                    start: 'top +=20%', // was: 30
                  },
                  autoAlpha: 1,
                  y: 0,
                }}
              >
                <H3
                  fontSize={['3rem', '4rem', '5rem']}
                  lineHeight={['3.3rem', '4.3rem', '6rem']}
                  my={0}
                >
                  {Intl.formatMessage({ id: 'aboutUs.general.founders' })}
                </H3>
              </Tween>
              <Div
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                flexWrap="wrap"
                gridGap="3rem"
              >
                <Tween
                  from={{
                    autoAlpha: 0,
                    y: 100,
                    scale: 0.8,
                  }}
                  to={{
                    scrollTrigger: {
                      trigger: '.trigger-founders',
                      start: 'top +=20%', // was: 30
                    },
                    autoAlpha: 1,
                    scale: 1,
                    y: 0,
                  }}
                  duration={0.3}
                  stagger={0.3}
                >
                  <Div flexBasis={['100%', '48%', '32%', '22.5%']}>
                    <Image
                      fixed={data.yann.childImageSharp.fixed}
                      alt="Yann"
                      style={{ maxWidth: '215px', height: '215px', marginBottom: '2rem' }}
                    />
                    <H3 fontSize={['2.5rem']} lineHeight={['3rem']} my="0" marginBottom="1rem">
                      <FormattedMessage
                        id="aboutUs.founderYann.name"
                        values={{
                          br: <br />,
                        }}
                      />{' '}
                      <br />
                    </H3>
                    <H4 my="0">
                      <FormattedMessage
                        id="aboutUs.founderYann.persona"
                        values={{
                          br: <br />,
                        }}
                      />
                    </H4>
                    <P>
                      <FormattedMessage
                        id="aboutUs.founderYann.description"
                        values={{
                          br: <br />,
                        }}
                      />
                    </P>
                  </Div>

                  <Div flexBasis={['100%', '48%', '32%', '22.5%']}>
                    <Image
                      fixed={data.anatol.childImageSharp.fixed}
                      alt="Anatol"
                      style={{ maxWidth: '215px', height: '215px', marginBottom: '2rem' }}
                    />
                    <H3 fontSize={['2.5rem']} lineHeight={['3rem']} my="0" marginBottom="1rem">
                      <FormattedMessage
                        id="aboutUs.founderAnatol.name"
                        values={{
                          br: <br />,
                        }}
                      />{' '}
                      <br />
                    </H3>
                    <H4 my="0">
                      <FormattedMessage
                        id="aboutUs.founderAnatol.persona"
                        values={{
                          br: <br />,
                        }}
                      />
                    </H4>
                    <P>
                      <FormattedMessage
                        id="aboutUs.founderAnatol.description"
                        values={{
                          br: <br />,
                        }}
                      />
                    </P>
                  </Div>

                  <Div flexBasis={['100%', '48%', '32%', '22.5%']}>
                    <Image
                      fixed={data.deniz.childImageSharp.fixed}
                      alt="Deniz"
                      style={{ maxWidth: '215px', height: '215px', marginBottom: '2rem' }}
                    />
                    <H3 fontSize={['2.5rem']} lineHeight={['3rem']} my="0" marginBottom="1rem">
                      <FormattedMessage
                        id="aboutUs.founderDeniz.name"
                        values={{
                          br: <br />,
                        }}
                      />{' '}
                      <br />
                    </H3>
                    <H4 my="0">
                      <FormattedMessage
                        id="aboutUs.founderDeniz.persona"
                        values={{
                          br: <br />,
                        }}
                      />
                    </H4>
                    <P>
                      <FormattedMessage
                        id="aboutUs.founderDeniz.description"
                        values={{
                          br: <br />,
                        }}
                      />
                    </P>
                  </Div>

                  <Div flexBasis={['100%', '48%', '32%', '22.5%']}>
                    <Image
                      fixed={data.felix.childImageSharp.fixed}
                      alt="Felix"
                      style={{ maxWidth: '215px', height: '215px', marginBottom: '2rem' }}
                    />
                    <H3 fontSize={['2.5rem']} lineHeight={['3rem']} my="0" marginBottom="1rem">
                      <FormattedMessage
                        id="aboutUs.founderFelix.name"
                        values={{
                          br: <br />,
                        }}
                      />{' '}
                      <br />
                    </H3>
                    <H4 my="0">
                      <FormattedMessage
                        id="aboutUs.founderFelix.persona"
                        values={{
                          br: <br />,
                        }}
                      />
                    </H4>
                    <P>
                      <FormattedMessage
                        id="aboutUs.founderFelix.description"
                        values={{
                          br: <br />,
                        }}
                      />
                    </P>
                  </Div>
                </Tween>
              </Div>
            </Div>
          </Div>
          <Div
            display="grid"
            className="trigger-team"
            alignItems="center"
            maxWidth="1440px"
            minHeight={['100vh', '100vh', '100vh', '100vh', '680px']}
            mx="auto"
            px={['8.75%', '8.75%', '8.75%', '8rem']}
            py={['5%', '5%', '10%', '10%']}
          >
            <Div
              display="grid"
              gridGap="80px"
              gridTemplateColumns={['1fr', '1fr', '1fr', '1fr', '240px 1fr']}
            >
              <Tween
                from={{
                  autoAlpha: 0,
                  y: 50,
                }}
                to={{
                  scrollTrigger: {
                    trigger: '.trigger-team',
                    start: 'top +=20%', // was: 30
                  },
                  autoAlpha: 1,
                  y: 0,
                }}
              >
                <H3
                  fontSize={['3rem', '4rem', '5rem']}
                  lineHeight={['3.3rem', '4.3rem', '6rem']}
                  my={0}
                >
                  Team
                </H3>
              </Tween>
              <Div
                width="100%"
                display="grid"
                gridGap="3rem"
                gridTemplateColumns={['1fr', '1fr', '2fr 2fr', '2fr 2fr', '50% 50%']}
              >
                <Tween
                  from={{
                    autoAlpha: 0,
                    y: 100,
                    scale: 0.8,
                  }}
                  to={{
                    scrollTrigger: {
                      trigger: '.trigger-team',
                      start: 'top +=20%', // was: 30
                    },
                    autoAlpha: 1,
                    scale: 1,
                    y: 0,
                  }}
                  duration={0.3}
                  stagger={0.2}
                >
                  <Div display="flex" flexDirection={['column', 'column', 'row']}>
                    <Image
                      fixed={data.anne.childImageSharp.fixed}
                      alt="Anne"
                      style={{
                        maxWidth: '225px',
                        height: '225px',
                        marginBottom: '2rem',
                      }}
                    />
                    <Div className="desc" mx="2.5rem">
                      <H3 fontSize={['2.5rem']} lineHeight={['3rem']} my="0" marginBottom="1.5rem">
                        Anne Hufnagel <br />
                      </H3>
                      <P my="0">
                        Business Development <br />
                        Partner Management <br />
                        Branding and Marketing
                      </P>
                    </Div>
                  </Div>

                  <Div display="flex" flexDirection={['column', 'column', 'row']}>
                    <Image
                      fixed={data.arbias.childImageSharp.fixed}
                      alt="Arbias"
                      style={{
                        maxWidth: '225px',
                        height: '225px',
                        marginBottom: '2rem',
                      }}
                    />
                    <Div className="desc" mx="2.5rem">
                      <H3 fontSize={['2.5rem']} lineHeight={['3rem']} my="0" marginBottom="1.5rem">
                        Arbias Gjoshi
                      </H3>
                      <P my="0">Senior Frontend Engineer</P>
                    </Div>
                  </Div>

                  <Div display="flex" flexDirection={['column', 'column', 'row']}>
                    <Image
                      fixed={data.kate.childImageSharp.fixed}
                      alt="Kate"
                      style={{
                        maxWidth: '225px',
                        height: '225px',
                        marginBottom: '2rem',
                      }}
                    />
                    <Div className="desc" mx="2.5rem">
                      <H3 fontSize={['2.5rem']} lineHeight={['3rem']} my="0" marginBottom="1.5rem">
                        Kate B.
                      </H3>

                      <P my="0">
                        Senior Cloud Architect <br />
                        Senior Backend Engineer <br />
                      </P>
                    </Div>
                  </Div>
                  <Div display="flex" flexDirection={['column', 'column', 'row']}>
                    <Image
                      fixed={data.rita.childImageSharp.fixed}
                      alt="Rita"
                      style={{
                        maxWidth: '225px',
                        height: '225px',
                        marginBottom: '2rem',
                      }}
                    />
                    <Div className="desc" mx="2.5rem">
                      <H3 fontSize={['2.5rem']} lineHeight={['3rem']} my="0" marginBottom="1.5rem">
                        Rita Mehmeti
                      </H3>
                      <P my="0">
                        Scrum Master <br />
                        Product Management <br />
                      </P>
                    </Div>
                  </Div>

                  <Div display="flex" flexDirection={['column', 'column', 'row']}>
                    <Image
                      fixed={data.rron.childImageSharp.fixed}
                      alt="Kate"
                      style={{
                        maxWidth: '225px',
                        height: '225px',
                        marginBottom: '2rem',
                      }}
                    />
                    <Div className="desc" mx="2.5rem">
                      <H3 fontSize={['2.5rem']} lineHeight={['3rem']} my="0" marginBottom="1.5rem">
                        Rron J.
                      </H3>

                      <P my="0">Software & DevOps Engineer</P>
                    </Div>
                  </Div>
                  <Div display="flex" flexDirection={['column', 'column', 'row']}>
                    <Image
                      fixed={data.sevgjan.childImageSharp.fixed}
                      alt="Sevgjan"
                      style={{
                        maxWidth: '225px',
                        height: '225px',
                        marginBottom: '2rem',
                      }}
                    />
                    <Div className="desc" mx="2.5rem">
                      <H3 fontSize={['2.5rem']} lineHeight={['3rem']} my="0" marginBottom="1.5rem">
                        Sevgjan Haxhija
                      </H3>
                      <P my="0">Senior iOS Software Engineer</P>
                    </Div>
                  </Div>

                  <Div display="flex" flexDirection={['column', 'column', 'row']}>
                    <Image
                      fixed={data.mask.childImageSharp.fixed}
                      alt="Rita"
                      style={{
                        maxWidth: '225px',
                        height: '225px',
                        marginBottom: '2rem',
                      }}
                    />
                    <Div className="desc" mx="2.5rem">
                      <H3 fontSize={['2.5rem']} lineHeight={['3rem']} my="0" marginBottom="1.5rem">
                        Nora Bislimi
                      </H3>
                      <P my="0">
                        Junior Graphic Designer
                        <br />
                      </P>
                    </Div>
                  </Div>
                </Tween>
              </Div>
            </Div>
          </Div>
          <Div
            display="grid"
            className="supervisory-team"
            alignItems="center"
            maxWidth="1440px"
            minHeight={['100vh', '100vh', '100vh', '100vh', '680px']}
            mx="auto"
            px={['8.75%', '8.75%', '8.75%', '8rem']}
            py={['5%', '5%', '10%', '10%']}
          >
            <Div
              display="grid"
              gridGap="80px"
              gridTemplateColumns={['1fr', '1fr', '1fr', '1fr', '240px 1fr']}
            >
              <Tween
                from={{
                  autoAlpha: 0,
                  y: 50,
                }}
                to={{
                  scrollTrigger: {
                    trigger: '.supervisory-team',
                    start: 'top +=20%', // was: 30
                  },
                  autoAlpha: 1,
                  y: 0,
                }}
              >
                <H3
                  fontSize={['3rem', '4rem', '5rem']}
                  lineHeight={['3.3rem', '4.3rem', '6rem']}
                  my={0}
                >
                  Supervisory Board
                </H3>
              </Tween>
              <Div
                width="100%"
                display="grid"
                gridGap="3rem"
                gridTemplateColumns={[
                  '1fr',
                  '1fr',
                  '2fr 2fr',
                  '250px 250px 250px',
                  '250px 250px 250px',
                ]}
              >
                <Tween
                  from={{
                    autoAlpha: 0,
                    y: 100,
                    scale: 0.8,
                  }}
                  to={{
                    scrollTrigger: {
                      trigger: '.supervisory-team',
                      start: 'top +=20%', // was: 30
                    },
                    autoAlpha: 1,
                    scale: 1,
                    y: 0,
                  }}
                  duration={0.3}
                  stagger={0.2}
                >
                  <Div display="flex" flexDirection="column">
                    <Image
                      fixed={data.heiko.childImageSharp.fixed}
                      alt="Heiko"
                      style={{
                        maxWidth: '225px',
                        height: '225px',
                        marginBottom: '2rem',
                      }}
                    />
                    <H3 fontSize={['2.5rem']} lineHeight={['3rem']} my="0" marginBottom="1.5rem">
                      <FormattedMessage
                        id="aboutUs.supervisoryBoard1.name"
                        values={{
                          br: <br />,
                        }}
                      />
                      <br />
                    </H3>
                    <A
                      href="https://www.moresophy.com/"
                      target="_blank"
                      style={{ marginBottom: '1rem', display: 'block' }}
                    >
                      www.moresophy.com
                    </A>
                    <P my="0">
                      <FormattedMessage
                        id="aboutUs.supervisoryBoard1.info"
                        values={{
                          br: <br />,
                        }}
                      />
                      <br />
                    </P>
                  </Div>
                  <Div>
                    <Image
                      fixed={data.ulrich.childImageSharp.fixed}
                      alt="Ulrich"
                      style={{
                        maxWidth: '225px',
                        height: '225px',
                        marginBottom: '2rem',
                      }}
                    />

                    <H3 fontSize={['2.5rem']} lineHeight={['3rem']} my="0" marginBottom="1.5rem">
                      <FormattedMessage
                        id="aboutUs.supervisoryBoard2.name"
                        values={{
                          br: <br />,
                        }}
                      />
                      <br />
                    </H3>

                    <A
                      href="https://www.kanzlei.de/"
                      target="_blank"
                      style={{ marginBottom: '1rem', display: 'block' }}
                    >
                      www.kanzlei.de
                    </A>

                    <P my="0">
                      <FormattedMessage
                        id="aboutUs.supervisoryBoard2.info"
                        values={{
                          br: <br />,
                        }}
                      />
                      <br />
                    </P>
                  </Div>

                  <Div>
                    <Image
                      fixed={data.wolfram.childImageSharp.fixed}
                      alt="Wolfram"
                      style={{
                        maxWidth: '225px',
                        height: '225px',
                        marginBottom: '2rem',
                      }}
                    />
                    <H3 fontSize={['2.5rem']} lineHeight={['3rem']} my="0" marginBottom="1.5rem">
                      <FormattedMessage
                        id="aboutUs.supervisoryBoard3.name"
                        values={{
                          br: <br />,
                        }}
                      />
                      <br />
                    </H3>

                    <A
                      href="https://www.3winters.de/"
                      target="_blank"
                      style={{ marginBottom: '1rem', display: 'block' }}
                    >
                      www.3winters.de
                    </A>
                    <P my="0">
                      <FormattedMessage
                        id="aboutUs.supervisoryBoard3.info"
                        values={{
                          br: <br />,
                        }}
                      />
                      <br />
                    </P>
                  </Div>
                </Tween>
              </Div>
            </Div>
          </Div>
          <Div minHeight="10rem" />
        </Section>
        <Section id="contact-info" data-section={2}>
          <Div
            className="trigger-contact-info"
            maxWidth="1440px"
            minHeight={['50vh', '50vh', '50vh', 'auto', 'auto']}
            mx="auto"
            py={['5rem', '5rem', '10rem']}
            px={['8.75%', '8.75%', '8.75%', '8rem']}
          >
            <Div
              display="grid"
              gridGap="80px"
              gridTemplateColumns={['1fr', '1fr 1fr', '1fr 1fr 1fr 1fr', '240px 200px 200px 200px']}
            >
              <Tween
                from={{
                  autoAlpha: 0,
                  y: 50,
                }}
                to={{
                  scrollTrigger: {
                    trigger: '.trigger-founders',
                    start: 'top +=50%', // was: 100
                  },
                  autoAlpha: 1,
                  y: 0,
                }}
              >
                <H3
                  fontSize={['3rem', '4rem', '5rem']}
                  lineHeight={['3.3rem', '4.3rem', '6rem']}
                  my={0}
                >
                  {Intl.formatMessage({ id: 'aboutUs.general.contactUs' })}
                </H3>
                <Div>
                  <H3>{Intl.formatMessage({ id: 'aboutUs.general.postalAddress' })}:</H3>
                  <P>
                    Justt AG <br />
                    Arnrieder Straße 12 <br />
                    81477 München <br />
                    Deutschland
                  </P>
                </Div>
                <Div>
                  <H3>{Intl.formatMessage({ id: 'aboutUs.general.emailFounders' })}:</H3>
                  <A href="mailto:founders@justt.me" style={{ marginBottom: '1.5rem' }}>
                    founders@justt.me
                  </A>
                  <H3>{Intl.formatMessage({ id: 'aboutUs.general.emailSupport' })}:</H3>
                  <A href="mailto:support@justt.me" style={{ marginBottom: '1.5rem' }}>
                    support@justt.me
                  </A>
                </Div>
                <Div>
                  <H3>{Intl.formatMessage({ id: 'aboutUs.general.DataPrivacyMatters' })}:</H3>
                  <A href="mailto:privacy@justt.me" style={{ marginBottom: '1.5rem' }}>
                    privacy@justt.me
                  </A>
                </Div>
              </Tween>
            </Div>
          </Div>
        </Section>
        <Section minHeight="120px" data-section={4}>
          <Footer />
        </Section>
      </main>
    </Layout>
  );
};

export const query = graphql`
  query getData {
    yann: file(relativePath: { eq: "team/Yann.jpg" }) {
      childImageSharp {
        fixed(width: 450, height: 450) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    anatol: file(relativePath: { eq: "team/Anatol.jpg" }) {
      childImageSharp {
        fixed(width: 450, height: 450) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    deniz: file(relativePath: { eq: "team/Deniz.jpg" }) {
      childImageSharp {
        fixed(width: 450, height: 450) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    felix: file(relativePath: { eq: "team/Felix.jpg" }) {
      childImageSharp {
        fixed(width: 450, height: 450) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    anne: file(relativePath: { eq: "team/Anne.png" }) {
      childImageSharp {
        fixed(width: 450, height: 450) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    arbias: file(relativePath: { eq: "team/Arbias.png" }) {
      childImageSharp {
        fixed(width: 450, height: 450) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    kate: file(relativePath: { eq: "team/Kate.jpg" }) {
      childImageSharp {
        fixed(width: 450, height: 450) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    rron: file(relativePath: { eq: "team/Rroni.png" }) {
      childImageSharp {
        fixed(width: 450, height: 450) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    rita: file(relativePath: { eq: "team/Rita.jpg" }) {
      childImageSharp {
        fixed(width: 450, height: 450) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    sevgjan: file(relativePath: { eq: "team/Sevgjan.png" }) {
      childImageSharp {
        fixed(width: 450, height: 450) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    mask: file(relativePath: { eq: "team/Mask.png" }) {
      childImageSharp {
        fixed(width: 450, height: 450) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    heiko: file(relativePath: { eq: "team/Heiko.png" }) {
      childImageSharp {
        fixed(width: 450, height: 450) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    ulrich: file(relativePath: { eq: "team/Ulrich.png" }) {
      childImageSharp {
        fixed(width: 450, height: 450) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    wolfram: file(relativePath: { eq: "team/Wolfram.png" }) {
      childImageSharp {
        fixed(width: 450, height: 450) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`;

export default AboutUs;
