import React from 'react';
import scrollTo from 'gatsby-plugin-smoothscroll';
import { A } from '../styles';

export default ({ height }) => (
  <A
    href="#intro"
    onClick={(evt) => {
      evt.preventDefault();
      scrollTo('#intro');
    }}
  >
    <svg height={height || 30} viewBox="0 0 95 30" xmlns="http://www.w3.org/2000/svg">
      <g transform="translate(0 -9)" fill="none" fillRule="evenodd">
        <path
          d="M10.137 38.586c6.035 0 9.629-3.418 9.629-9.414V9.816h-5.899v19.317c0 2.832-1.328 4.355-3.789 4.355-2.226 0-3.71-1.504-3.75-3.789H.605c0 5.39 3.77 8.887 9.532 8.887zm21.738-.273c3.262 0 5.371-1.504 6.465-4.2h.117V38h5.508V16.496h-5.703v12.246c0 2.91-1.66 4.824-4.317 4.824-2.636 0-4.082-1.562-4.082-4.472V16.496H24.16v13.79c0 5 3.067 8.026 7.715 8.026zm24.57.058c5.625 0 9.473-2.754 9.473-6.934 0-3.203-1.719-4.863-6.035-5.84l-4.043-.878c-1.934-.45-2.871-1.172-2.871-2.246 0-1.387 1.367-2.305 3.418-2.305 2.187 0 3.613 1.074 3.73 2.773h5.274c-.079-4.101-3.614-6.816-8.926-6.816-5.45 0-9.004 2.656-9.004 6.7 0 3.144 2.09 5.234 6.133 6.152l3.965.898c1.953.469 2.695 1.055 2.695 2.168 0 1.406-1.387 2.285-3.652 2.285-2.364 0-3.829-1.016-4.102-2.832h-5.566c.293 4.14 3.847 6.875 9.511 6.875zm20.899-.293c1.152 0 2.168-.117 2.754-.234v-4.219a9.798 9.798 0 01-1.426.098c-1.738 0-2.559-.801-2.559-2.461v-10.45h3.985v-4.316h-3.985v-4.785H70.41v4.785H67.5v4.317h2.91v11.386c0 4.2 1.953 5.88 6.934 5.88zm14.472 0c1.153 0 2.168-.117 2.754-.234v-4.219a9.798 9.798 0 01-1.425.098c-1.739 0-2.56-.801-2.56-2.461v-10.45h3.985v-4.316h-3.984v-4.785h-5.703v4.785h-2.91v4.317h2.91v11.386c0 4.2 1.953 5.88 6.933 5.88z"
          fill="var(--logo-color)"
          fillRule="nonzero"
        />
        <circle fill="#00FF24" cx="4" cy="14" r="4" />
      </g>
    </svg>
  </A>
);
