import React, { useState } from 'react';
// import { useLocation } from 'react-use';
import styled from 'styled-components';
import { A } from '../styles';

const Div = styled.div`
  display: flex;
  z-index: 99999;
  a {
    color: #000;
    position: relative;
    padding-right: 3rem;
    opacity: 0.5;
    font-size: 1.5rem;
    line-height: 1.8rem;
    font-weight: 600;
  }
  a:last-child {
    padding-right: 0;
  }
  a::after {
    display: inline-block;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 1.5rem;
    content: '';
    width: 3px;
    height: 3px;
    background-color: #000;
    border-radius: 50%;
    opacity: 0.5;
  }
  a:last-child {
    margin-right: 0;
  }
  a:last-child::after {
    display: none;
  }
  a:hover,
  a:focus,
  a.is-current {
    opacity: 1;
  }
`;

export default () => {
  const [currentLink, setCurrentLink] = useState('/en/');
  const [pageRoute, setPageRoute] = useState(null);

  React.useEffect(() => {
    let path = window.location.pathname;
    const currentRoute = path.split('/')[2];
    if (currentRoute) {
      setPageRoute(currentRoute);
    }

    setCurrentLink(window.location.pathname);
  }, []);

  return (
    <Div bg="red">
      <A
        href={pageRoute ? `/en/${pageRoute}` : `/en/`}
        textDecoration="none"
        fontSize="1.5rem"
        fontWeight="600"
        className={currentLink === '/en/' && 'is-current'}
      >
        English
      </A>
      <A
        href={pageRoute ? `/de/${pageRoute}` : `/de/`}
        textDecoration="none"
        fontSize="1.5rem"
        fontWeight="600"
        className={currentLink === '/de/' && 'is-current'}
      >
        Deutsch
      </A>
    </Div>
  );
};
