import React, { useRef, useState } from 'react';
import { AnimatePresence, motion, useViewportScroll, useTransform, useSpring } from 'framer-motion';
import styled from 'styled-components';
import { flexbox, space, color, layout, position, typography } from 'styled-system';
import { useIntl, Link } from 'gatsby-plugin-intl';
// import scrollTo from "gatsby-plugin-smoothscroll"
// import Scrollspy from 'react-scrollspy';
// import { gsap } from 'gsap';
import Logo from '../logo';
import { Div } from '../styles';

const Span = styled(motion.span)`
  ${flexbox};
  ${typography};
  ${space};
  ${position};
  ${layout};
  ${color};
`;

const Header = styled(motion.header)`
  position: sticky;
  top: 0;
  z-index: 20;
  background-color: var(--header-background);
  backdrop-filter: blur(5px);
  ${flexbox};
  ${space};
  ${layout};

  .container .navigation-desktop div {
    width: 710px;
    @media screen and (min-width: 1024px) {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    @media screen and (min-width: 1281px) {
      width: 880px;
    }
  }

  .navigation-mobile {
    text-align: right;
  }

  .primary-link {
    color: var(--primary-link-color);
    opacity: 0.56;
    font-weight: 600;
    text-decoration: none;
    position: relative;
    transition: opacity 250ms cubic-bezier(0.5, 0, 0.5, 1);
    &:hover,
    &:focus,
    &.is-current {
      opacity: 1;
    }
    &.is-current::before {
      display: inline-block;
      position: absolute;
      top: 50%;
      left: -1em;
      transform: translateY(-50%);
      content: '';
      width: 8px;
      height: 8px;
      background-color: #12f76d;
      border-radius: 4px;
    }
  }

  .nav-progress-line {
    background-color: var(--progress-line-color);
    height: 5px;
    border-radius: 2.5px;
    position: relative;
    top: 16px;
    &::before {
      content: '';
      display: inline-block;
      position: absolute;
      width: calc(var(--percent-line-progress) * 1%);
      will-change: width;
      height: 5px;
      background-color: #00ff24;
      border-radius: inherit;
    }
  }

  .nav-container > div {
    padding: 0 0.8rem;
  }

  .nav-container a {
    color: var(--primary-link-color);
    opacity: 0.56;
    font-weight: 600;
    text-decoration: none;
    position: relative;
    transition: opacity 250ms cubic-bezier(0.5, 0, 0.5, 1) 0s;

    &.current-menu-item {
      opacity: 1;
    }
  }
`;

export default () => {
  const Intl = useIntl();

  const navigation = useRef([
    Intl.formatMessage({ id: 'navigation.forContent' }),
    Intl.formatMessage({ id: 'navigation.forEveryone' }),
    Intl.formatMessage({ id: 'navigation.forBrands' }),
    Intl.formatMessage({ id: 'navigation.aboutUs' }),
    Intl.formatMessage({ id: 'navigation.stayTuned' }),
  ]);

  const [currentNav, setCurrentNav] = useState(undefined);

  const { scrollYProgress } = useViewportScroll();
  const progressPerc = useTransform(scrollYProgress, [-0, 1], [0, 100]);

  const yRange = useTransform(scrollYProgress, [0, 1], [0, 1]);
  const pathLength = useSpring(yRange, { stiffness: 400, damping: 90 });

  return (
    <Header
      className="header"
      py={['1.5rem', '1.5rem', '4rem']}
      progressPercent={100}
      style={{ '--percent-line-progress': 100 }}
    >
      <Div
        maxWidth="1440px"
        mx="auto"
        px={['8.75%', '8.75%', '8.75%', '8rem']}
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        className="container"
      >
        <Logo height="30" />
        <Div
          display={['flex', 'flex', 'flex', 'none']}
          flexDirection="column"
          alignItems="flex-end"
          className="navigation-mobile"
        >
          <Div display="flex" alignItems="center">
            <AnimatePresence exitBeforeEnter>
              {currentNav && (
                <Span
                  style={{ display: 'inline-flex' }}
                  color="var(--primary-link-color)"
                  fontSize="1.2rem"
                  fontWeight={500}
                  key={currentNav}
                  initial={{ y: -15, opacity: 0 }}
                  animate={{ y: 0, opacity: 1 }}
                  exit={{ y: 15, opacity: 0 }}
                  m={0}
                  p={0}
                >
                  {navigation.current[currentNav]}
                </Span>
              )}
            </AnimatePresence>
            <Div ml="1rem" position="relative">
              <svg className="progress-icon" viewBox="0 0 49 49" width="34px" height="34px">
                <motion.path
                  fill="none"
                  strokeWidth="5"
                  stroke="#02FF3A"
                  strokeDasharray="0 1"
                  d="M 0, 20 a 20, 20 0 1,0 40,0 a 20, 20 0 1,0 -40,0"
                  style={{
                    pathLength,
                    rotate: 90,
                    translateX: 5,
                    translateY: 5,
                    scaleX: -1,
                  }}
                />
                <motion.path
                  style={{
                    rotate: 90,
                    translateX: 5,
                    translateY: 5,
                    scaleX: -1,
                  }}
                  className="secondPath"
                  fill="none"
                  strokeWidth="5"
                  stroke="#EAEAEA"
                  strokeDasharray="1 1"
                  d="M 0, 20 a 20, 20 0 1,0 40,0 a 20, 20 0 1,0 -40,0"
                />
              </svg>
            </Div>
          </Div>
        </Div>
        <Div
          display={['none', 'none', 'none', 'flex']}
          flexDirection="column"
          alignItems="center"
          className="navigation-desktop"
        >
          <Div className="nav-container">
            <Link to="/" activeClassName="current-menu-item">
              Home
            </Link>
            <Link to="/for-content-creators" activeClassName="current-menu-item">
              {Intl.formatMessage({ id: 'navigation.forContent' })}
            </Link>
            <Link to="/for-readers" activeClassName="current-menu-item">
              {Intl.formatMessage({ id: 'navigation.forEveryone' })}
            </Link>
            <Link to="/for-brands" activeClassName="current-menu-item">
              {Intl.formatMessage({ id: 'navigation.forBrands' })}
            </Link>
            <Link to="/about-us" activeClassName="current-menu-item">
              {Intl.formatMessage({ id: 'navigation.aboutUs' })}
            </Link>
          </Div>
          <Div className="nav-progress-line" width="100%" height="5px" />
        </Div>
      </Div>
    </Header>
  );
};
